<template>
  <div>
    <currency-type :objectType="objectType"></currency-type>
  </div>
</template>
<script>
import CurrencyType from "@/components/FileType/CurrencyType";
export default {
  data(){
    return{
      objectType: {
        Type: 5,  //总结报告
        FlowName: false,
        FileName: "总结报告"
      },
    }
  },
  components:{
    CurrencyType
  },
};
</script>
